// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


const authenticityToken = document.querySelector('meta[name="csrf-token"]');

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
      .then(function(registration) {
        console.log('Service worker registered:', registration.scope);
      }, function(err) {
        console.log('Service worker registration failed:', err);
      });
  });
}


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import 'select2';

document.addEventListener("turbolinks:load", function() {
  var userDropdown = document.querySelector(".user-dropdown");
  var dropdownContent = document.querySelector(".dropdown-content");

  $('.select2').select2();

  if (userDropdown && dropdownContent) {
    userDropdown.addEventListener("click", function() {
      dropdownContent.classList.toggle("show");
    });
  }

  document.addEventListener("click", function(event) {
    if (userDropdown && dropdownContent && !userDropdown.contains(event.target)) {
      dropdownContent.classList.remove("show");
    }
  });
});

document.addEventListener('DOMContentLoaded', function() {
  const flashNotice = document.getElementById('flash-notice');
  const flashAlert = document.getElementById('flash-alert');
  const flashInfo = document.getElementById('flash-info');
  const flashWarning = document.getElementById('flash-warning');

  const hideFlashMessage = (flashElement) => {
    flashElement.classList.add('fade-out');
    setTimeout(() => {
      flashElement.remove();
    }, 500); // Set the timeout to match the CSS transition duration
  };

  const hideAfterTimeout = (flashElement) => {
    setTimeout(() => {
      hideFlashMessage(flashElement);
    }, 4000);
  };

  if (flashNotice) {
    hideAfterTimeout(flashNotice);

    flashNotice.addEventListener('click', function() {
      hideFlashMessage(flashNotice);
    });
  }

  if (flashAlert) {
    hideAfterTimeout(flashAlert);

    flashAlert.addEventListener('click', function() {
      hideFlashMessage(flashAlert);
    });
  }

  if (flashInfo) {
    hideAfterTimeout(flashInfo);

    flashInfo.addEventListener('click', function() {
      hideFlashMessage(flashInfo);
    });
  }

  if (flashWarning) {
    hideAfterTimeout(flashWarning);

    flashWarning.addEventListener('click', function() {
      hideFlashMessage(flashWarning);
    });
  }
});
